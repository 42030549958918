<template>
  <div style="padding-bottom: 4.5%">
    <div v-if="isLoading">
      <OtherLoading />
    </div>
    <div>
      <div>
        <h1 class="mr-sm-4 header-tablepage">Activity Log Detail</h1>
      </div>
      <div class="title-panel mt-3">Activity Detail</div>

      <div class="container-box bg-white py-2">
        <div class="row flex-column px-4 space-div">
          <div class="ft-bold">Date :</div>
          <div v-if="detail.created_time_string">
            {{ detail.created_time_string }}
          </div>
          <div v-else>-</div>
          <div class="ft-bold">Email :</div>
          <div v-if="detail.display_name">{{ detail.display_name }}</div>
          <div v-else>-</div>
          <div class="ft-bold">Name - Surname :</div>
          <div v-if="detail.display_name">{{ detail.display_name }}</div>
          <div v-else>-</div>
          <div class="ft-bold">IP Address :</div>
          <div v-if="detail.ip_address">{{ detail.ip_address }}</div>
          <div v-else>-</div>
          <div class="ft-bold">Activity :</div>
          <div class="text-wrap-show" v-if="detail.activity">
            {{ detail.activity }}
          </div>
          <div v-else>-</div>
          <div class="ft-bold">Target :</div>
          <div class="text-wrap-show" v-if="detail.target">
            {{ detail.target }}
          </div>
          <div v-else>-</div>
          <div class="ft-bold">Description :</div>
          <div class="text-wrap-show" v-if="detail.detail">
            {{ detail.detail }}
          </div>
          <div v-else>-</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import OtherLoading from "@/components/loading/OtherLoading";
export default {
  components: {
    OtherLoading,
  },
  data() {
    return {
      id: this.$route.params.id,
      detail: {},
      isLoading: true,
    };
  },
  async created() {
    await this.getDetailById();
  },
  methods: {
    async getDetailById() {
      await this.$store.dispatch("getDetailById", this.id);
      const data = this.$store.state.activitylog.stateDetailById;
      if (data.result === 1) {
        this.detail = data.detail;
        this.isLoading = false;
      } else {
        this.isLoading = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.text-wrap-show {
  word-wrap: break-word;
}
.ft-bold {
  font-weight: bold;
}
.space-div {
  div:nth-child(even) {
    margin-bottom: 1px;
    margin-left: 1rem;
  }
  div {
    padding: 0.25rem;
  }
}
</style>
